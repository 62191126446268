import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

export default () => (
  <Layout>
    <SEO
      title="Thank you!"
      keywords={[
        "electrical",
        "Philippines",
        "design",
        "build",
        "system",
        "supply",
        "troubleshoot",
        "repair",
        "equipments",
        "test",
        "manage",
        "bms"
      ]}
    />
    <div className="d-md-flex align-items-md-center height-100vh--md">
      <div className="container text-center space-2 space-3--lg">
        <div className="w-md-80 w-lg-60 text-center mx-md-auto">
          <div className="mb-5">
            <span className="u-icon u-icon--success u-icon--lg rounded-circle mb-4">
              <span className="fa fa-check u-icon__inner"></span>
            </span>
            <h1 className="h2 mb-0">Thank you!</h1>
            <h2 className="h2">We have received your message.</h2>
            <p>We will get back to in you 1 to 3 business days.</p>
          </div>
          <Link to="/services/" className="btn btn-success">
            Continue Browsing
          </Link>
        </div>
      </div>
    </div>
  </Layout>
);
